import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import NavItem from './NavItem';

import Router from '@material-ui/icons/Router';
import Description from '@material-ui/icons/Description';
import WifiTethering from '@material-ui/icons/WifiTethering';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpOutline from '@material-ui/icons/HelpOutline';
import ExitToApp from '@material-ui/icons/ExitToApp';

import * as  AuthActions from '../../../actions/user.actions';
import { useActions } from '../../../actions';
// Modules
import { useSelector } from 'react-redux';

const items = [{
  href: '/app/devices',
  icon: Router,
  title: 'Devices'
}, {
  href: '/app/device-logs',
  icon: Description,
  title: 'Device Logs'
}, {
  href: '/app/find-devices',
  icon: WifiTethering,
  title: 'Find Devices'
}];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: '256px'
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
}));

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

// tslint:disable-next-line: variable-name
const NavBar = ({ onMobileClose, openMobile }: Props) => {
  const location = useLocation();
  const classes = useStyles();

  const user = useSelector((state: RootState) => state.user.data);
  const authActions = useActions(AuthActions);

  const logout = () => {
    authActions.logout();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
    >
      <Hidden mdUp>
        <div>
          <img src='/assets/headerwithLogo-guardDog.png' style={{ width: '256px' }} />
        </div>
      </Hidden>
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        p={2}
      >
        <Typography
          color='textPrimary'
          variant='h5'
        >
          Welcome to Cloud Console,
        </Typography>
        <Typography
          // className={classes.name}
          color='textPrimary'
          variant='h5'
        >
          {user.displayName}
        </Typography>
        <Typography
          color='textSecondary'
          variant='body2'
        >
          {user.companyName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              Icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box
        p={2}
      >
        <List>
          <NavItem
            href={'/app/account'}
            key={'Account'}
            title={'Account'}
            Icon={AccountCircle}
          />
          <NavItem
            href={'https://guarddog.ai/contact-3/contact/'}
            key={'Support'}
            title={'Support'}
            Icon={HelpOutline}
          />
          <NavItem
            href={'/#'}
            key={'Log Out'}
            title={'Log Out'}
            Icon={ExitToApp}
            action={logout}
          />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          anchor='left'
          open
          variant='permanent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Page from '../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

import * as  DeviceActions from '../../actions/devices.actions';
import { useActions } from '../../actions';
// Modules
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// tslint:disable-next-line: variable-name
const LogListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();

  const user = useSelector((state: RootState) => state.user);
  const device = useSelector((state: RootState) => state.device);
  const deviceActions = useActions(DeviceActions);

  let items = device.logs;
  if (device.lastAction === DeviceActions.DeviceActions.SET_FILTERED_LOGS) {
    items = device.filteredLogs;
  }

  useEffect(() => {
    if (!user.loginSuccess) {
      return navigate('/login', { replace: true });
    }
    const deviceId = query.get('deviceId');
    if (device.lastAction !== DeviceActions.DeviceActions.SET_LOGS &&
      device.lastAction !== DeviceActions.DeviceActions.SET_FILTERED_LOGS) {
      deviceActions.getLogs(user.data, deviceId);
    } else {
      if ((!device.lastDeviceId && deviceId) || (device.lastDeviceId && !deviceId)) {
        deviceActions.getLogs(user.data, deviceId);
      }
    }
  });

  const handleSearch = (keyword: string) => {
    deviceActions.filterLogs(keyword);
  };

  return (
    <Page
      className={classes.root}
      title='Logs'
    >
      <Container maxWidth={false}>
        <Typography color='textSecondary' variant='h4'>
          Devices Logs
        </Typography>
        <Toolbar search={handleSearch} />
        <Box mt={3}>
          <Results
            logs={items}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LogListView;

import * as React from 'react';

export function Critical(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={115}
      height={49}
      viewBox='0 0 115 49'
      {...props}
    >
      <defs>
        <ellipse id='prefix__b' cx={28.69} cy={22.5} rx={20.71} ry={20.5} />
        <filter
          id='prefix__a'
          width='200%'
          height='200%'
          x='-50%'
          y='-50%'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feOffset dy={2} in='SourceAlpha' result='shadow-1-feOffset-shadow' />
          <feGaussianBlur
            in='shadow-1-feOffset-shadow'
            result='shadow-1-feGaussianBlur-shadow'
            stdDeviation={2.41}
          />
          <feColorMatrix
            in='shadow-1-feGaussianBlur-shadow'
            result='shadow-1-feColorMatrix-shadow'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
        </filter>
      </defs>
      <use
        stroke='#000'
        strokeOpacity={0}
        strokeWidth={0}
        filter='url(#prefix__a)'
        xlinkHref='#prefix__b'
      />
      <use fill='#FFF' xlinkHref='#prefix__b' />
      <path
        fill='#FF5F58'
        fillOpacity={0.8}
        fillRule='evenodd'
        d='M10 22.163c0-10.21 8.29-18.5 18.5-18.5 7.734 0 14.366 4.756 17.128 11.5H101.5a9.5 9.5 0 010 19H42.576c-3.394 3.977-8.443 6.5-14.076 6.5-10.21 0-18.5-8.29-18.5-18.5z'
      />
      <path
        fill='#FFF'
        fillRule='evenodd'
        d='M29.75 31.71H26V28h3.75v3.71zM29.75 25.421H26V12h3.75v13.421z'
      />
      <text
        fontSize={0}
        style={{
          whiteSpace: 'pre',
        }}
      >
        <tspan
          x={43}
          y={30}
          fill='#FFF'
          fontFamily='Roboto'
          fontSize={15}
          fontWeight={700}
          style={{
            whiteSpace: 'pre',
            textTransform: 'none',
          }}
          letterSpacing={1}
        >
          {'Critical'}
        </tspan>
      </text>
    </svg>
  );
}

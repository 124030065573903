import axios from 'axios';
// Config
import config from '../config';

function mapResult(result: FirebaseResult) {
  const objectData = result.data;
  if (!objectData) {
    return [];
  }
  const newData: any[] = [];
  for (const id in objectData) {
    if (Object.prototype.hasOwnProperty.call(objectData, id)) {
      const record = objectData[id];
      newData.push({
        id,
        ...record,
      });
    }
  }
  return newData;
}

function mapIpMapResult(result: FirebaseResult) {
  const objectData = result.data;
  if (!objectData) {
    return [];
  }
  const newData: any[] = [];
  for (const deviceId in objectData) {
    if (Object.prototype.hasOwnProperty.call(objectData, deviceId)) {
      const record = objectData[deviceId];
      const keysQty = Object.keys(record);
      if (keysQty.length > 1) {
        newData.push({
          id: deviceId,
          device_id: deviceId,
          ...record,
        });
      } else {
        const id = keysQty[0];
        if (Object.prototype.hasOwnProperty.call(record, id)) {
          const ipMap = record[id];
          newData.push({
            id,
            device_id: deviceId,
            ...ipMap,
          });
        }
      }
    }
  }
  return newData;
}

export async function getUsersDevices(token: string) {
  const result = await axios.get(`${config.DATABASE_URL}/user_devices.json?auth=${token}`);
  return mapResult(result);
}

export async function getThreats(token: string) {
  const result = await axios.get(`${config.DATABASE_URL}/threat_level.json?auth=${token}`);
  return mapResult(result);
}

export async function getIpMap(token: string) {
  const result = await axios.get(`${config.DATABASE_URL}/ip_map.json?auth=${token}`);
  return mapIpMapResult(result);
}

export async function saveUserDevice(user: User, device: DeviceIpMap, action?: 'add' | 'remove') {
  if (device.userDeviceId) {
    return await axios.patch(`${config.DATABASE_URL}/user_devices/${device.userDeviceId}.json?auth=${user.userToken}`, {
      device: device.device,
      serialNumber: device.serialNumber,
      monitoring: action ? (action === 'add' ? true : false) : device.monitoring,
    });
  } else {
    const data = {
      device: device.device,
      private: device.private,
      public: device.public,
      device_id: device.device_id,
      monitoring: action ? (action === 'add' ? true : false) : false,
      uid: user.uid,
      ip_map_id: device.id,
      serialNumber: device.serialNumber,
    };
    return await axios.post(`${config.DATABASE_URL}/user_devices.json?auth=${user.userToken}`, data);
  }
}

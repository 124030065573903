import React, { useState } from 'react';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  }
}));

interface Props {
  className?: string;
  search: (keyword: string) => void;
}

// tslint:disable-next-line: variable-name
const Toolbar = ({ className, search, ...rest }: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (e: { keyCode: number; }) => {
    if (e.keyCode === 13) {
      console.log('34: value >>>', value);
      search(value);
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={3} display='flex'>
        <Box maxWidth={400} className={classes.searchInput}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon
                    fontSize='small'
                    color='action'
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            size='small'
            placeholder='Search log'
            variant='outlined'
          />
        </Box>
      </Box>
    </div>
  );
};

export default Toolbar;

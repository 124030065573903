import axios from 'axios';
import { batch } from 'react-redux';
import to from 'await-to-js';

import Firebase from '../utils/firebase';
import config from '../config';
import { AppActions } from './app.actions';

import { DeviceActions } from './devices.actions';

const firebase = new Firebase();
const app = firebase.init();

export enum UserActions {
  SET_USER = 'SET_USER',
  LOG_OUT = 'LOG_OUT',
  PROCESSING_UPDATE = 'PROCESSING_UPDATE',
  SET_UPDATED_USER = 'SET_UPDATED_USER',
  CLOSE_USER_ALERTS = 'CLOSE_USER_ALERTS',
  SET_UPDATED_PASSWORD_ERROR = 'SET_UPDATED_PASSWORD_ERROR',
  SET_UPDATED_PASSWORD_SUCCESS = 'SET_UPDATED_PASSWORD_SUCCESS',
}

const saveUserData = async (token: string, data: UserData) => {
  return await axios.post(`${config.DATABASE_URL}/users.json?auth=${token}`, data);
};

export function authenticate(email: string, password: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    return app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        const firebaseUser = result.user;
        if (!firebaseUser) {
          throw new Error('User does not exists.');
        }
        if (!firebaseUser.emailVerified) {
          throw new Error('Your email is not verified.');
        }
        const token = await firebaseUser.getIdToken();
        const firbaseDisplayName = firebaseUser.displayName;
        const userObject: Partial<User> = {
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firbaseDisplayName,
          emailVerified: firebaseUser.emailVerified,
          userToken: token,
          refreshToken: firebaseUser.refreshToken,
          additionalUserInfo: result.additionalUserInfo,
        };

        const [errorUsers, usersResponse] = await to(axios.get(`${config.DATABASE_URL}/users.json?auth=${token}`));
        if (errorUsers) {
          throw errorUsers;
        }

        const users: UserData[] = usersResponse?.data;
        for (const key in users) {
          if (Object.prototype.hasOwnProperty.call(users, key)) {
            const userRecord = users[key];
            if (userRecord.uid === firebaseUser.uid) {
              userObject.id = key;
              userObject.firstName = userRecord.firstName;
              userObject.lastName = userRecord.lastName;
              userObject.companyName = userRecord.companyName;
              if (!userObject.displayName) {
                userObject.displayName = userRecord.firstName;
              }
              break;
            }
          }
        }
        dispatch({ type: UserActions.SET_USER, payload: userObject });
        return userObject;
      })
      .catch((error) => {
        console.log('70: error >>>', error);
        throw error;
      });
  };
}

export function signUp(values: SignupData) {
  return async () => {
    return app
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then((result) => {
        const firebaseUser = result.user;
        if (!firebaseUser) {
          throw new Error('An error occurred.');
        }

        return firebaseUser.sendEmailVerification().then(async () => {
          const token = await firebaseUser.getIdToken();
          // const salt = bcrypt.genSaltSync(10);
          // const hash = bcrypt.hashSync(values.password, salt);

          const newUserData: UserData = {
            companyName: values.companyName,
            uid: firebaseUser.uid,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
          };

          try {
            await saveUserData(token, newUserData);
          } catch (error) {
            await firebaseUser.delete();
            throw error;
          }
          return;
        }).catch((error) => {
          console.log('116: error >>>', error);
          throw error;
        });
      }).catch((error) => {
        console.log('120: error >>>', error);
        let message = error.message;
        if (error.code === 'auth/email-already-in-use') {
          message += `-${error.code}`;
        }
        throw new Error(message);
      });
  };
}

export function updateUser(token: string, values: UserInfo) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    dispatch({ type: UserActions.PROCESSING_UPDATE, payload: true });
    const [error, result] = await to(axios.patch(`${config.DATABASE_URL}/users/${values.id}.json?auth=${token}`, {
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
    }));
    dispatch({ type: UserActions.PROCESSING_UPDATE, payload: false });
    if (error) {
      console.log('137: error >>>', error);
      return dispatch({ type: AppActions.ERROR, payload: error.message });
    }
    return dispatch({ type: UserActions.SET_UPDATED_USER, payload: result?.data });
  };
}

export function updatePassword(user: User, values: ChangePassword) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    if (values.newPassword.toLowerCase() !== values.confirmNewPassword.toLowerCase()) {
      return dispatch({ type: UserActions.SET_UPDATED_PASSWORD_ERROR, payload: 'New password does not match' });
    }
    const email = user?.email ? user.email : '';
    return app
      .auth()
      .signInWithEmailAndPassword(email, values.currentPassword)
      .then(async (result) => {
        const currentUser = app.auth().currentUser;
        if (!currentUser) {
          return dispatch({ type: UserActions.SET_UPDATED_PASSWORD_ERROR, payload: new Error('Someting wrong') });
        }

        currentUser?.updatePassword(values.confirmNewPassword).then(() => {
          return dispatch({ type: UserActions.SET_UPDATED_PASSWORD_SUCCESS, payload: 'Password updated successfully' });
        }).catch((error) => {
          return dispatch({ type: UserActions.SET_UPDATED_PASSWORD_ERROR, payload: error.message });
        });

      }).catch((error) => {
        return dispatch({ type: UserActions.SET_UPDATED_PASSWORD_ERROR, payload: error.message });
      });
  };
}

export function closeUserAlerts() {
  return { type: UserActions.CLOSE_USER_ALERTS };
}

export function resendEmailVerification(email: string) {
  return async () => {
    return app.auth().signInAnonymously()
      .then(async (result) => {
        const anonymouslyUser = result.user;
        if (!anonymouslyUser) {
          throw new Error('User does not exists.');
        }

        const token = await anonymouslyUser.getIdToken();
        const [errorUsers, usersResponse] = await to(axios.get(`${config.DATABASE_URL}/users.json?auth=${token}`));
        if (errorUsers) {
          await anonymouslyUser.delete();
          throw errorUsers;
        }

        const users: UserData[] = usersResponse?.data;
        let userDb = null;
        for (const key in users) {
          if (Object.prototype.hasOwnProperty.call(users, key)) {
            const userRecord = users[key];
            if (userRecord.email === email) {
              userDb = userRecord;
            }
          }
        }
        if (!userDb) {
          await anonymouslyUser.delete();
          throw new Error('User does not exists in our database.');
        }

        const password = userDb.password || '';
        return app.auth().signOut().then(() => {
          return app
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(async (response) => {
              const firebaseUser = response.user;
              await anonymouslyUser.delete();

              if (!firebaseUser) {
                throw new Error('User does not exists.');
              }
              if (firebaseUser.emailVerified) {
                throw new Error('Your email is already verified.');
              }
              return firebaseUser.sendEmailVerification().then(() => {
                return;
              }).catch((error) => {
                console.log('229: error >>>', error);
                throw new Error(error.message);
              });
            }).catch((error) => {
              console.log('227: error >>>', error);
              throw new Error(error.message);
            });
        }).catch(async (error) => {
          await anonymouslyUser.delete();
          throw new Error(error.message);
        });
      })
      .catch((error) => {
        console.log('233: error >>>', error);
        throw new Error(error.message);
      });
  };
}

export function sendPasswordResetEmail(email: string) {
  return async () => {
    app.auth().sendPasswordResetEmail(email).then(() => {
      return;
    }).catch((error) => {
      throw new Error(error.message);
    });
  };
}

export function logout() {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    app.auth().signOut().then(() => {
      return batch(() => {
        dispatch({ type: UserActions.LOG_OUT });
        dispatch({ type: DeviceActions.CLEAR });
      });
    });
  };
}

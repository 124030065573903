import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import SimpleLayout from './layouts/SimpleLayout';

import AccountView from './pages/account/AccountView';
import Devices from './pages/devices';
import FindDevices from './pages/findDevices';
import Logs from './pages/logs';
import LoginView from './pages/auth/LoginView';
import NotFoundView from './pages/errors/NotFoundView';
import RegisterView from './pages/auth/RegisterView';
import ValidationView from './pages/auth/ValidationView';

const routes = [{
  path: 'app',
  element: <DashboardLayout />,
  children: [
    { path: 'account', element: <AccountView /> },
    { path: 'devices', element: <Devices /> },
    { path: 'device-logs', element: <Logs /> },
    { path: 'find-devices', element: <FindDevices /> },
    { path: '*', element: <Navigate to='/404' /> }
  ]
}, {
  path: 'validate',
  element: <SimpleLayout />,
  children: [
    { path: 'email', element: <ValidationView /> },
  ]
}, {
  path: '/',
  element: <MainLayout />,
  children: [
    { path: 'login', element: <LoginView /> },
    { path: 'sign-up', element: <RegisterView /> },
    { path: '404', element: <NotFoundView /> },
    { path: '/', element: <Navigate to='/login' /> },
    { path: '*', element: <Navigate to='/404' /> }
  ]
}];

export default routes;
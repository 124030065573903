import { AppActions } from '../actions/app.actions';
import createReducer from './createReducer';

const initialState: App = {
  error: '',
};

export const app = createReducer<App>(initialState, {
  [AppActions.ERROR](state: App, action: AppAction) {
    return {
      ...state,
      error: action.payload,
    };
  },
});

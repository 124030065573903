import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  logoLink: {
    height: '100%',
    padding: '5px 0',
  }
}));

interface Props {
  className?: string;
  onMobileNavOpen: () => void;
}

// tslint:disable-next-line: variable-name
const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar style={{ height: 64 }}>
        <RouterLink to='/' className={classes.logoLink}>
          <Logo style={{ height: '100%' }} />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            color='inherit'
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

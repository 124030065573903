import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.slider': {
      height: '100% !important',
      maxHeight: 800,
    },
    '.slider-list': {
      height: '100% !important',
      maxHeight: 800,
    },
    '.slider-list > li.slider-slide': {
      height: '100% !important',
    },
    '.paging-item > button': {
      fill: '#fff !important'
    }
  }
}));

// tslint:disable-next-line: variable-name
const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

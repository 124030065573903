import { History } from 'history';
import { combineReducers } from 'redux';
import * as user from './user';
import * as device from './device';
import * as app  from './app';

export interface RootState {
  user: User;
  device: DeviceReducer;
  app: App;
}

export default (history: History) =>
  combineReducers({
    ...user,
    ...device,
    ...app,
  });

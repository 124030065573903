import React, { useState } from 'react';
import clsx from 'clsx';
import { Search as SearchIcon } from 'react-feather';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {},
  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },
  toolBarContainer: {
    marginTop: theme.spacing(3),
  },
  toolBarItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

interface Props {
  className?: string;
  addDevices: () => void;
  processing: boolean;
  search: (keyword: string) => void;
}

// tslint:disable-next-line: variable-name
const Toolbar = ({ className, addDevices, processing, search, ...rest }: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (e: { keyCode: number; }) => {
    if (e.keyCode === 13) {
      console.log('34: value >>>', value);
      search(value);
    }
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid container justify='space-between' className={classes.toolBarContainer}>
        <Hidden smUp>
          <Grid item className={classes.toolBarItem} container justify='flex-end'>
            <Box>
              {processing && <CircularProgress />}
              {!processing && <Button variant='contained' color='primary' onClick={addDevices}>
                Register Devices
              </Button>}
            </Box>
          </Grid>
        </Hidden>
        <Grid item className={classes.toolBarItem}>
          <Box maxWidth={500} className={classes.searchInput}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon
                      fontSize='small'
                      color='action'
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              size='small'
              placeholder='Search device'
              variant='outlined'
            />
          </Box>
        </Grid>
        <Hidden only={['xs']}>
          <Grid item className={classes.toolBarItem}>
            <Box>
              {processing && <CircularProgress />}
              {!processing && <Button variant='contained' color='primary' onClick={addDevices}>
                Register Devices
              </Button>}
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default Toolbar;

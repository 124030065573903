import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  className?: string;
  children: any;
}

// tslint:disable-next-line: variable-name
const Page = forwardRef((props: Props, ref: any) => {
  return (
    <div
      ref={ref}
      {...props}
    >
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </div>
  );
});

export default Page;

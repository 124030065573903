import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Page from '../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

import * as  DeviceActions from '../../actions/devices.actions';
import { useActions } from '../../actions';
// Modules
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

// tslint:disable-next-line: variable-name
const FindDeviceListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const device = useSelector((state: RootState) => state.device);
  const deviceActions = useActions(DeviceActions);

  const selectedDevicesIds = device.selectedFoundDevicesIds;
  const publicIp = device.publicIp;
  let items = device.foundDevices;
  if (device.lastAction === DeviceActions.DeviceActions.SET_FILTERED_FOUND_DEVICES) {
    items = device.filteredFoundDevices;
  }

  useEffect(() => {
    if (!user.loginSuccess) {
      return navigate('/login', { replace: true });
    }
    if (!device.searchingDevices &&
      device.lastAction !== DeviceActions.DeviceActions.SET_FOUND_DEVICES &&
      device.lastAction !== DeviceActions.DeviceActions.SET_FILTERED_FOUND_DEVICES) {
      deviceActions.findDevices(user.data);
    }
  });

  const addDevices = () => {
    deviceActions.addDevice(user.data, items, selectedDevicesIds);
  };

  const handleSelectAll = (event: { target: { checked: any; }; }) => {
    const newSelectedCustomerIds: string[] =
      event.target.checked ? items.map((foundDevice) => foundDevice.id) : [];
    deviceActions.setSelectedDevicesIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (_: any, id: string) => {
    const selectedIndex = selectedDevicesIds.indexOf(id);
    let newSelectedDevicesIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedDevicesIds = newSelectedDevicesIds.concat(selectedDevicesIds, id);
    } else if (selectedIndex === 0) {
      newSelectedDevicesIds = newSelectedDevicesIds.concat(selectedDevicesIds.slice(1));
    } else if (selectedIndex === selectedDevicesIds.length - 1) {
      newSelectedDevicesIds = newSelectedDevicesIds.concat(selectedDevicesIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedDevicesIds = newSelectedDevicesIds.concat(
        selectedDevicesIds.slice(0, selectedIndex),
        selectedDevicesIds.slice(selectedIndex + 1)
      );
    }
    deviceActions.setSelectedDevicesIds(newSelectedDevicesIds);
  };

  const handleSearch = (keyword: string) => {
    deviceActions.filterFoundDevices(keyword);
  };

  return (
    <Page
      className={classes.root}
      title='Find devices'
    >
      <Container maxWidth={false}>
        <Typography color='textSecondary' variant='h4'>
          Find Devices - ({publicIp})
        </Typography>
        <Toolbar
          addDevices={addDevices}
          processing={device.processingAdd}
          search={handleSearch}
        />
        <Box mt={3}>
          <Results
            user={user.data}
            devices={items}
            handleSelectAll={handleSelectAll}
            handleSelectOne={handleSelectOne}
            selectedDevicesIds={selectedDevicesIds}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default FindDeviceListView;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import Page from '../../../components/Page';
import Profile from './Profile';
import UpdatePassword from './UpdatePassword';

import * as UserActions from '../../../actions/user.actions';
import { useActions } from '../../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

// tslint:disable-next-line: variable-name
const Account = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const userActions = useActions(UserActions);

  useEffect(() => {
    if (!user.loginSuccess) {
      return navigate('/login', { replace: true });
    }
  });

  const handleClose = () => {
    userActions.closeUserAlerts();
   };

  const handleSave = (values: UserInfo) => {
    userActions.updateUser(user.data.userToken, values);
  };

  const handleUpdate = (values: ChangePassword) => {
    userActions.updatePassword(user.data, values);
  };

  return (
    <Page
      className={classes.root}
      title='Account'
    >
      <Container maxWidth='lg'>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <Profile
              user={user.data}
              onSave={handleSave}
              processing={user.processingUpdate}
            />
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
          >
            <UpdatePassword updatePassword={handleUpdate} />
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={Boolean(user.updatePasswordError)} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error'>
          {user.updatePasswordError}
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(user.updatePasswordSuccess)} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          {user.updatePasswordSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={Boolean(user.updateUserSuccess)} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success'>
          {user.updateUserSuccess}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default Account;

import React from 'react';

// tslint:disable-next-line: variable-name
const Logo = (props: any) => {
  return (
    <img
      alt='Logo'
      src='/assets/guardDogLogo-guardDog.png'
      {...props}
    />
  );
};

export default Logo;

import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import HelpOutline from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconSupport: {
    fontSize: '1.7rem !important',
    paddingLeft: '2px !important',
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.text.secondary,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.text.secondary
    }
  }
}));

interface Props {
  className?: string;
  href: string;
  Icon: any;
  title: string;
  action?: () => void;
}

const NavItem = ({
  className,
  href,
  Icon,
  title,
  action = () => {
    // any clear comments.
  },
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      {href.match('https') && <Button
        color='primary'
        startIcon={<HelpOutline className={classes.iconSupport} />}
        className={classes.button}
        href={href}
        target='_blank'
      >
        <span className={classes.title}>
          {title}
        </span>
      </Button>}
      {!href.match('https') && <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        onClick={action}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size='20'
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
      </Button>}
    </ListItem>
  );
};

export default NavItem;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Page from '../../components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

import * as DeviceActions from '../../actions/devices.actions';
import { useActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeviceListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const { filteredDevices, devices, lastAction } = useSelector((state: RootState) => state.device);
  const deviceActions = useActions(DeviceActions);
  let items = devices;
  if (lastAction === DeviceActions.DeviceActions.SET_FILTERED_DEVICES) {
    items = filteredDevices;
  }

  useEffect(() => {
    if (!user.loginSuccess) {
      return navigate('/login', { replace: true });
    }
    if (lastAction !== DeviceActions.DeviceActions.SET_DEVICES &&
      lastAction !== DeviceActions.DeviceActions.SET_FILTERED_DEVICES) {
      deviceActions.getDevices(user.data);
    }
  });

  const handleSearch = (keyword: string) => {
    deviceActions.filterDevices(keyword);
  };

  return (
    <Page
      className={classes.root}
      title='Devices'
    >
      <Container maxWidth={false}>
        <Typography color='textSecondary' variant='h4'>
          Devices
        </Typography>
        <Toolbar search={handleSearch} />
        <Box mt={3}>
          <Results devices={items} />
        </Box>
      </Container>
    </Page>
  );
};

export default DeviceListView;

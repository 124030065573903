import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import DeleteIcon from '@material-ui/icons/Delete';
import Description from '@material-ui/icons/Description';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';

import { Critical, High, Low, None } from './icons';
import ThreatIcon from '../../components/ThreatIcon';

import * as DeviceActions from '../../actions/devices.actions';
import { useActions } from '../../actions';
// Modules
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const drawIcon = (threat: Threat | undefined) => {
  if (!threat) {
    return null;
  }
  switch (Number(threat.threat_level)) {
    case 0:
      return <None height={30} />;
    case 1:
      return <Low height={30} />;
    case 2:
      return <High height={30} />;
    case 3:
      return <Critical height={30} />;
    default:
      return null;
  }
};

interface Props {
  className?: string;
  devices: UserDevices[];
}

interface DescriptionProps {
  data: ThreatDialog;
  onClose: () => void;
}

interface ConfirmationProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function DescriptionDialog({ onClose, data }: DescriptionProps) {
  const date = data.threat?.date ? new Date(data.threat?.date).toLocaleString() : '';
  return (
    <div>
      <Dialog
        open={data.open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='center' m={0.5}>
            <Box>
              <ThreatIcon
                threat={data.threat}
                height={40}
                color='primary'
                style={{ fontSize: 40 }}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' >
            <Box>
              <Typography color='textPrimary' variant='h4'>
                Threat Description
            </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {data.threat?.threat_description}
          </DialogContentText>
          <DialogContentText>
            {date}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary' variant='contained' autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ConfirmationDialog({ open, onClose, onConfirm }: ConfirmationProps) {
  return (
    <div>
      <Dialog
        open={open}
        maxWidth={'xs'}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='center' m={0.5}>
            <Box>
              <InfoIcon color='primary' style={{ fontSize: 50 }} />
            </Box>
          </Box>
          <Box display='flex' justifyContent='center'>
            <Box>
              <Typography color='textPrimary' variant='h4'>
                Are you sure?
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are going to remove this device from you Network
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary' variant='contained'>
            Cancel
            </Button>
          <Button onClick={onConfirm} color='primary' variant='contained' autoFocus>
            Yes
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  device: string;
  device_id: string;
  public: string;
  private: string;
  serialNumber: string;
  threat_date?: string;
  threat_description?: string;
  threat_level?: string;
}

const headCells: HeadCell[] = [
  { id: 'device', numeric: false, disablePadding: true, label: 'Device Name' },
  { id: 'threat_level', numeric: false, disablePadding: false, label: 'Threat Level' },
  { id: 'threat_description', numeric: false, disablePadding: false, label: 'Threat Description' },
  { id: 'threat_date', numeric: false, disablePadding: false, label: 'Date / Time' },
  { id: 'public', numeric: false, disablePadding: false, label: 'Public IP' },
  { id: 'private', numeric: false, disablePadding: false, label: 'Private IP' },
  { id: 'serialNumber', numeric: false, disablePadding: false, label: 'Serial Number' },
  { id: 'device_id', numeric: false, disablePadding: false, label: 'Device ID' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const Results = ({ className, devices, ...props }: Props) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('threat_level');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState<UserDevices | null>(null);
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);
  const Device = useSelector((state: RootState) => state.device);
  const deviceActions = useActions(DeviceActions);

  const handleLimitChange = (event: any) => {
    // tslint:disable-next-line: radix
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleOpenThreat = (threat: Threat) => {
    deviceActions.openThreatDialog(threat);
  };

  const handleCloseThreat = () => {
    deviceActions.clearThreatDialog();
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmation = () => {
    setOpenConfirmation(false);
    deviceActions.removeDevice(user.data, deviceToRemove);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const items: UserDeviceData[] = devices.map((device) => {
    return {
      ...device,
      threat_description: device.threat ? device.threat.threat_description : '',
      threat_date: device.threat ? device.threat.date : '',
      threat_level: device.threat ? device.threat.threat_level : '',
      serialNumber: device.serialNumber ? device.serialNumber : '',
    };
  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...props}
    >
      <DescriptionDialog data={Device.threatDialog} onClose={handleCloseThreat} />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmation}
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((device: UserDeviceData, index: number) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell>
                      <Box component='div' display='inline'>
                        <IconButton onClick={() => {
                          return navigate(`/app/device-logs?deviceId=${device.device_id}`);
                        }}>
                          <Description />
                        </IconButton>
                      </Box>
                      <Box component='div' display='inline'>
                        <IconButton onClick={() => {
                          if (device.threat) {
                            handleOpenThreat(device.threat);
                          }
                        }}>
                          <VisibilityIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {device.device}
                    </TableCell>
                    <TableCell>
                      {drawIcon(device.threat)}
                    </TableCell>
                    <TableCell>
                      <Box component='div' display='inline'>
                        {device.threat && device.threat.threat_description}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {device.threat && new Date(device.threat.date).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {device.public}
                    </TableCell>
                    <TableCell>
                      {device.private}
                    </TableCell>
                    <TableCell>
                      {device.serialNumber}
                    </TableCell>
                    <TableCell>
                      {device.device_id}
                    </TableCell>
                    <TableCell padding='checkbox'>
                      <IconButton onClick={() => {
                        setDeviceToRemove(device);
                        setOpenConfirmation(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={devices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card >
  );
};

export default Results;

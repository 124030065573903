import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';

import Carousel from 'nuka-carousel';

import Page from '../../components/Page';

// Actions
import { useActions } from '../../actions';
import * as  AuthActions from '../../actions/user.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120%',
    backgroundPositionX: -45,
    backgroundPositionY: 50,
    height: '70%',
  },
  gridContainer: {
    height: '100%',
  },
  gridItemContainer: {
    height: 'auto',
    overflow: 'hidden',
    maxHeight: 800,
  },
  titleCarrousel: {
    color: '#fff',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface ItemProps {
  item: {
    name: string;
    description: string;
    img: string;
  };
}

interface TabPanelProps {
  children?: any;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth='xs'>
          {children}
        </Container>
      )}
    </div>
  );
}

function Item(props: ItemProps) {
  const classes = useStyles();
  const { item } = props;

  return (
    <>
      <div style={{
        backgroundImage: `url(${item.img})`
      }} className={classes.imageContainer}
      ></div>
      <div>
        <Box display='flex' justifyContent='center' m={1} p={1} >
          <Box p={1}>
            <Typography variant='h2' className={classes.titleCarrousel}>
              {item.name}
            </Typography>
            <Typography variant='h5' className={classes.titleCarrousel}>
              {item.description}
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
}

// tslint:disable-next-line: variable-name
const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tab, setTab] = useState(0);
  const query = useQuery();

  useEffect(() => {
    const queryAction = query.get('action');
    if (queryAction === 'recovpass') {
      setTab(1);
    }
  }, []);

  const items = [{
    name: 'Exposes',
    description: 'Exposes hidden threats on your Wi-Fi network',
    img: '/assets/SOC-Login-Images-1.png',
  }, {
    name: 'Reports',
    description: 'Reports attacks and vulnerabilities color-coded by threat level',
    img: '/assets/SOC-Login-Images-2.png',
  }, {
    name: 'Mitigates',
    description: 'Mitigates threats for every device it finds on your Wi-Fi',
    img: '/assets/SOC-Login-Images-3.png',
  }];
  const authActions = useActions(AuthActions);

  return (
    <Page
      className={classes.root}
      title='Login'
    >
      <Container maxWidth='lg' style={{ height: '100vh', maxHeight: 800 }}>
        <Grid container className={classes.gridContainer}>
          <Hidden only={['xs']}>
            <Grid item md={5} sm={5} style={{ backgroundColor: '#2295D3', }} className={classes.gridItemContainer}>
              <Carousel
                height={'100vh'}
                autoplay={true}
                enableKeyboardControls={true}
                autoGenerateStyleTag={true}
                autoplayReverse={true}
              >
                {items.map((item, index) => (
                  <Item key={index} item={item} />
                ))}
              </Carousel>
            </Grid>
          </Hidden>
          <Grid item md={7} sm={7} xs={12} className={classes.gridItemContainer}>
            <Box
              display='flex'
              flexDirection='column'
              height='100%'
              alignContent='center'
              justifyContent='center'
            >
              <TabPanel value={tab} index={0}>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    keepLogin: false,
                    error: '',
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required'),
                    keepLogin: Yup.boolean(),
                    error: Yup.string(),
                  })}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    authActions.authenticate(values.email, values.password).then(() => {
                      navigate('/app/devices', { replace: true });
                    }).catch((err: Error) => {
                      setSubmitting(false);
                      setErrors({ error: err.message });
                    });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}>
                        <Typography
                          color='textPrimary'
                          variant='h2'
                        >
                          Log in
                      </Typography>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='body2'
                        >
                          Log in with your data that you entered during sign up
                      </Typography>
                      </Box>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        size='small'
                        fullWidth
                        helperText={touched.email && errors.email}
                        label='Email Address'
                        margin='normal'
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='email'
                        value={values.email}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        size='small'
                        fullWidth
                        helperText={touched.password && errors.password}
                        label='Password'
                        margin='normal'
                        name='password'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='password'
                        value={values.password}
                        variant='outlined'
                      />
                      {Boolean(touched.error && errors.error) && (
                        <FormHelperText error>
                          {errors.error}
                        </FormHelperText>
                      )}
                      <Box my={2}>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                        >
                          Log in
                        </Button>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        {isSubmitting && <Box my={2}> <CircularProgress /> </Box>}
                      </Box>
                      <Box
                        alignItems='center'
                        display='flex'
                        ml={-1}
                      >
                        <Checkbox
                          checked={values.keepLogin}
                          name='keepLogin'
                          onChange={handleChange}
                        />
                        <Typography
                          color='textSecondary'
                          variant='body1'
                        >
                          Keep me logged in
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Typography
                          color='textSecondary'
                          variant='body1'
                        >
                          Don't have an account?
                          {' '}
                          <Link
                            component={RouterLink}
                            to='/sign-up'
                            variant='h6'
                          >
                            Sign up
                          </Link>
                        </Typography>
                      </Box>
                      <Box mt={3}>
                        <Typography
                          color='textSecondary'
                          variant='body1'
                        >
                          <Link
                            component='button'
                            variant='h5'
                            onClick={() => {
                              setTab(1);
                            }}
                          >
                            Forgot your password?
                          </Link>
                        </Typography>
                      </Box>
                    </form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Box mb={3}>
                  <Box my={5}>
                    <Typography
                      color='textPrimary'
                      variant='h2'
                    >
                      Password recovery
                    </Typography>
                    <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='body2'
                    >
                      To reset your password, submit your email address below.
                      An email will be sent to your email address,
                      with instructions of how to get access again.
                    </Typography>
                    <Formik
                      initialValues={{
                        email: '',
                        error: '',
                        message: '',
                      }}
                      validationSchema={
                        Yup.object().shape({
                          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                          error: Yup.string(),
                          message: Yup.string(),
                        })
                      }
                      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                        authActions.sendPasswordResetEmail(values.email).then(() => {
                          setSubmitting(false);
                          setErrors({ message: 'Check your email out to complete the process!' });
                        }).catch((error: Error) => {
                          setSubmitting(false);
                          setErrors({ error: error.message });
                        });
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            size='small'
                            fullWidth
                            helperText={touched.email && errors.email}
                            label='Email Address'
                            margin='normal'
                            name='email'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='email'
                            value={values.email}
                            variant='outlined'
                          />
                          {Boolean(touched.error && errors.error) && (
                            <FormHelperText error>
                              {errors.error}
                            </FormHelperText>
                          )}
                          <Box my={2}>
                            <Button
                              color='primary'
                              disabled={isSubmitting}
                              fullWidth
                              size='large'
                              type='submit'
                              variant='contained'
                            >
                              Send email
                            </Button>
                          </Box>
                          {Boolean(touched.message && errors.message) && (
                            <Alert severity='success'>
                              {'Check your email out to complete the process!'}
                            </Alert>
                          )}
                        </form>
                      )}
                    </Formik>
                    <Box mt={5}>
                      <Typography
                        color='textSecondary'
                        variant='body1'
                      >
                        Back to
                        {' '}
                        <Link
                          component='button'
                          variant='h5'
                          onClick={() => {
                            setTab(0);
                          }}
                        >
                          Log in
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page >
  );
};

export default LoginView;

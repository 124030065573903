import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import firebaseApp from 'firebase/app';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Alert from '@material-ui/lab/Alert';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Firebase from '../../utils/firebase';
import config from '../../config';
import Page from '../../components/Page';

const firebase = new Firebase();
const app = firebase.init();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

async function handleResetPassword(auth: firebaseApp.auth.Auth, actionCode: string) {
  // Verify the password reset code is valid.
  return auth.verifyPasswordResetCode(actionCode).then((email) => {
    // TODO: Show the reset screen with the user's email and ask the user for
    return;
  }).catch((error) => {
    throw error;
  });
}

async function handleVerifyEmail(auth: firebaseApp.auth.Auth, actionCode: string) {
  return auth.applyActionCode(actionCode).then(() => {
    // Email address has been verified.
    // TODO: Display a confirmation message to the user.
    // You could also provide the user with a link back to the app.
    return;
  }).catch((error) => {
    throw error;
  });
}

type Status = 'ERROR' | 'PROCESSING' | 'VERIFIED';

const ValidationView = () => {
  const classes = useStyles();
  const query = useQuery();

  const queryMode = query.get('mode');
  const queryOobCode = query.get('oobCode');
  const [mode] = useState<string | null>(queryMode);
  const [oobCode] = useState<string | null>(queryOobCode);
  const [status, setStatus] = useState<Status>('PROCESSING');
  const [error, setError] = useState<string>('');

  const changePassword = async (actionCode: string | null, newPassword: string) => {
    const code = actionCode || '';
    return app.auth().confirmPasswordReset(code, newPassword).then((resp) => {
      // Password reset has been confirmed and new password updated.

      // TODO: Display a link back to the app, or sign-in the user directly
      // if the page belongs to the same domain as the app:
      // auth.signInWithEmailAndPassword(accountEmail, newPassword);

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
      return;
    }).catch((error) => {
      throw error;
    });
  };

  const handleError = (message: string) => {
    setStatus('ERROR');
    setError(message);
  };

  useEffect(() => {
    const apiKey = query.get('apiKey');
    if (!apiKey) {
      return handleError('Invalid API Key.');
    } else if (apiKey && apiKey !== config.API_KEY) {
      return handleError('Invalid API Key.');
    }
    if (mode && oobCode) {
      (async () => {
        try {
          if (mode === 'verifyEmail') {
            await handleVerifyEmail(app.auth(), oobCode);
            setStatus('VERIFIED');
          } else if (mode === 'resetPassword') {
            await handleResetPassword(app.auth(), oobCode);
            setStatus('VERIFIED');
          }
        } catch (error) {
          handleError(error.message);
        }
      })();
    }
  }, []);

  return (
    <Page
      className={classes.root}
      title='Validation'
    >
      <Box
        display='flex'
        flexDirection='column'
        height='100%'
        alignContent='center'
        justifyContent='center'
      >
        {mode === 'verifyEmail' &&
          <Container maxWidth='xs'>
            <Box>
              <Box mb={3}>
                <Typography
                  color='textPrimary'
                  variant='h2'
                >
                  Email Verification
                </Typography>
                <Typography
                  color='textSecondary'
                  gutterBottom
                  variant='body2'
                >
                  You registered an account, before being able to use your
                  account we need to verify that this is your email address.
                </Typography>
              </Box>
              {status === 'PROCESSING' && <Box>
                <CircularProgress />
                <Typography
                  color='textSecondary'
                  gutterBottom
                  variant='body2'
                >
                  Checking
              </Typography>
              </Box>}
              {status === 'VERIFIED' && <Box>
                <Box mb={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    className={classes.button}
                    startIcon={<ArrowForwardIcon />}
                    href={'/login'}
                  >
                    Continue
                  </Button>
                </Box>
                <Alert severity='success'>
                  {'Your email has been validated sucessfully!'}
                </Alert>
              </Box>
              }
            </Box>
          </Container>
        }
        {mode === 'resetPassword' &&
          <Container maxWidth='xs'>
            <Box>
              <Box mb={3}>
                <Typography
                  color='textPrimary'
                  variant='h2'
                >
                  Password Recovery
                </Typography>
              </Box>
              {status === 'PROCESSING' && <Box>
                <Typography
                  color='textSecondary'
                  gutterBottom
                  variant='body2'
                >
                  Checking
                </Typography>
                <CircularProgress />
              </Box>}
              {status === 'VERIFIED' && <Box mb={2}>
                <Formik
                  initialValues={{
                    password: '',
                    newPassword: '',
                    error: '',
                    success: false,
                    message: '',
                  }}
                  validationSchema={
                    Yup.object().shape({
                      password: Yup.string().max(255).required('Password is required'),
                      newPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match'),
                      error: Yup.string(),
                      success: Yup.boolean(),
                      message: Yup.string(),
                    })
                  }
                  onSubmit={(values, { setSubmitting, setErrors, setFieldValue }) => {
                    changePassword(oobCode, values.password).then(() => {
                      setFieldValue('success', true, false);
                      setFieldValue('message', 'Your password was successfully updated.', false);
                      setSubmitting(false);
                    }).catch((error) => {
                      setErrors({ error: error.message });
                      setSubmitting(false);
                    });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {Boolean(!values.success) && (<Box>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='body2'
                        >
                          Please enter your new password below.
                        </Typography>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          size='small'
                          fullWidth
                          helperText={touched.password && errors.password}
                          label='Password'
                          margin='normal'
                          name='password'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type='password'
                          value={values.password}
                          variant='outlined'
                        />
                        <TextField
                          error={Boolean(touched.newPassword && errors.newPassword)}
                          size='small'
                          fullWidth
                          helperText={touched.newPassword && errors.newPassword}
                          label='Confirm New Password'
                          margin='normal'
                          name='newPassword'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type='password'
                          value={values.newPassword}
                          variant='outlined'
                        />
                        <Box my={2}>
                          <Button
                            color='primary'
                            disabled={isSubmitting}
                            fullWidth
                            size='large'
                            type='submit'
                            variant='contained'
                          >
                            New password
                          </Button>
                        </Box>
                      </Box>
                      )}
                      {Boolean(touched.error && errors.error) && (
                        <Alert severity='error'>
                          {errors.error}
                        </Alert>
                      )}
                      {Boolean(values.message) && (<Box>
                        <Box mb={3}>
                          <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            fullWidth
                            className={classes.button}
                            startIcon={<ArrowForwardIcon />}
                            href={'/login'}
                          >
                            Continue
                          </Button>
                        </Box>
                        <Alert severity='success'>
                          {values.message}
                        </Alert>
                      </Box>
                      )}
                    </form>
                  )}
                </Formik>
              </Box>}
            </Box>
          </Container>
        }
        <Container maxWidth={'xs'}>
          <Box my={1}>
            {status === 'ERROR' && <Alert severity='error'>{error}</Alert>}
          </Box>
          <Box mt={5}>
            <Typography
              color='textSecondary'
              variant='body1'
            >
              Back to
              {' '}
              <Link
                component={RouterLink}
                to='/login'
                variant='h6'
              >
                Log in
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ValidationView;

import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

interface Props {
  className?: string;
  updatePassword: (values: ChangePassword) => void;
}

// tslint:disable-next-line: variable-name
const UpdatePassword = ({ className, updatePassword, ...rest }: Props) => {
  const classes = useStyles();
  const [values, setValues] = useState<ChangePassword>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUpdate = () => {
    updatePassword(values);
  };

  return (
    <form
      autoComplete='off'
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader='We suggest update your password monthly'
          title='Update Password'
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
            >
              <TextField
                fullWidth
                label='Current Password'
                name='currentPassword'
                type='password'
                onChange={handleChange}
                value={values.currentPassword}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={12}
            >
              <TextField
                fullWidth
                label='New Password'
                name='newPassword'
                type='password'
                onChange={handleChange}
                value={values.newPassword}
                variant='outlined'
              />
            </Grid>
            <Grid
              item
              md={12}
            >
              <TextField
                fullWidth
                label='Confirm New Password'
                name='confirmNewPassword'
                type='password'
                onChange={handleChange}
                value={values.confirmNewPassword}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box
          display='flex'
          justifyContent='flex-end'
          p={2}
        >
          <Button
            color='primary'
            variant='contained'
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default UpdatePassword;

export default {
  API_KEY: 'AIzaSyBvd_-B5MAAxn5jgjqnKa2IG9_SkUZ6niw',
  AUTH_DOMAIN: 'guard-dog-257220.firebaseapp.com',
  DATABASE_URL: 'https://guard-dog-257220.firebaseio.com',
  PROJECT_ID: 'guard-dog-257220',
  STORAGE_BUCKET: 'guard-dog-257220.appspot.com',
  MESSAGING_SENDER_ID: '627749209674',
  APP_ID: '1:627749209674:web:74cee5fab52a14fed688b3',
  MEASUREMENT_ID: 'G-JZZVBXYSBY',
};

import React from 'react';
import { Critical, High, Low, None } from '../pages/devices/icons';

interface Props {
  threat: Threat | null;
  height?: number;
  [x: string]: any;
}

const ThreatIcon = ({ threat, height = 30, ...rest }: Props) => {
  if (!threat) {
    return null;
  }
  switch (Number(threat.threat_level)) {
    case 0:
      return <None height={height} {...rest} />;
    case 1:
      return <Low height={height} {...rest} />;
    case 2:
      return <High height={height} {...rest} />;
    case 3:
      return <Critical height={height} {...rest} />;
    default:
      return null;
  }
};

export default ThreatIcon;

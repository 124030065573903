import axios from 'axios';
import { batch } from 'react-redux';
import to from 'await-to-js';
import filter from 'lodash/filter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

import config from '../config';
import { AppActions } from './app.actions';

import { getUsersDevices, getThreats, getIpMap, saveUserDevice } from '../utils/dataHelper';

export enum DeviceActions {
  SET_DEVICES = 'SET_DEVICES',
  SET_FILTERED_DEVICES = 'SET_FILTERED_DEVICES',
  CLEAR = 'CLEAR',
  SET_FOUND_DEVICES = 'SET_FOUND_DEVICES',
  SET_FILTERED_FOUND_DEVICES = 'SET_FILTERED_FOUND_DEVICES',
  SET_LOGS = 'SET_LOGS',
  SET_FILTERED_LOGS = 'SET_FILTERED_LOGS',
  ADD_DEVICE = 'ADD_DEVICE',
  OPEN_THREAT_DIALOG = 'OPEN_THREAT_DIALOG',
  CLEAR_THREAT_DIALOG = 'CLEAR_THREAT_DIALOG',
  OPEN_DEVICE_DIALOG = 'OPEN_DEVICE_DIALOG',
  CLEAR_DEVICE_DIALOG = 'CLEAR_DEVICE_DIALOG',
  SET_DEVICE_NAME = 'SET_DEVICE_NAME',
  SET_SERIAL_NUMBER = 'SET_SERIAL_NUMBER',
  UPDATE_DEVICE_NAME = 'UPDATE_DEVICE_NAME',
  SEARCHING = 'SEARCHING',
  CLOSE_SEARCHING_MODAL = 'CLOSE_SEARCHING_MODAL',
  SET_LAST_DEVICE_ID = 'SET_LAST_DEVICE_ID',
  SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES',
  SET_PROCESSING_SAVE = 'SET_PROCESSING_SAVE',
  OPEN_CONFIRMATION_DIALOG = 'OPEN_CONFIRMATION_DIALOG',
  CLOSE_CONFIRMATION_DIALOG = 'CLOSE_CONFIRMATION_DIALOG',
  CLOSE_UPDATE_CONFIRMATION_DIALOG = 'CLOSE_UPDATE_CONFIRMATION_DIALOG',
  UPDATE_DEVICE = 'UPDATE_DEVICE',
  PROCESSING_ADD = 'PROCESSING_ADD',
  REMOVE_DEVICE = 'REMOVE_DEVICE',
}

export function getDevices(user: User) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    const [usersDevicesError, usersDevices] = await to<UsersDevices[]>(getUsersDevices(user.userToken));
    if (usersDevicesError) {
      console.log('43: usersDevicesError >>>', usersDevicesError);
      return dispatch({ type: AppActions.ERROR, payload: usersDevicesError.message });
    }
    if (!usersDevices?.length) {
      return dispatch({ type: DeviceActions.SET_DEVICES, payload: [] });
    }
    const [threatsError, threats] = await to<Threat[]>(getThreats(user.userToken));
    if (threatsError) {
      console.log('51: threatsError >>>', threatsError);
      return dispatch({ type: AppActions.ERROR, payload: threatsError.message });
    }

    const userDevices: UserDevices[] = filter(usersDevices, (device) =>
      device.uid === user.uid && device.monitoring);
    const devicesNames: string[] = map(userDevices, (device) => device.device_id);
    forEach(threats, (threat) => {
      if (devicesNames.indexOf(threat.device) !== -1) {
        const device = filter(userDevices, (item) => item.device_id === threat.device)[0];
        if (!device.threat) {
          device.threat = threat;
          return;
        }
        const threatDate = new Date(device.threat.date);
        const newThreatDate = new Date(threat.date);
        if (newThreatDate > threatDate) {
          device.threat = threat;
        }
      }
    });
    return dispatch({ type: DeviceActions.SET_DEVICES, payload: userDevices });
  };
}

export function findDevices(user: User, origin?: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    if (!origin) {
      dispatch({ type: DeviceActions.SEARCHING, payload: true });
    }

    const resulIp = await axios.get(`https://api.ipify.org/`);
    const publicIp = resulIp.data;
    console.log('83: publicIp >>>', publicIp);

    const [erroIpMapResult, ipMapResult] = await to<IpMap[]>(getIpMap(user.userToken));
    if (erroIpMapResult) {
      return batch(() => {
        dispatch({ type: DeviceActions.SEARCHING, payload: false });
        dispatch({ type: AppActions.ERROR, payload: erroIpMapResult.message });
      });
    }

    const [usersDevicesError, usersDevices] = await to<UsersDevices[]>(getUsersDevices(user.userToken));
    if (usersDevicesError) {
      return batch(() => {
        dispatch({ type: DeviceActions.SEARCHING, payload: false });
        dispatch({ type: AppActions.ERROR, payload: usersDevicesError.message });
      });
    }

    const foundDevices: DeviceIpMap[] = filter(ipMapResult, (ipMap) => ipMap.public === publicIp)
      .map((item) => ({ ...item, serialNumber: '' }));
    const foundDevicesIds: string[] = map(foundDevices, (foundDevice) => foundDevice.device_id);
    const userDevices: UserDevices[] = filter(usersDevices, (device) => device.uid === user.uid);

    forEach(userDevices, (device) => {
      const ipMapPosition = foundDevicesIds.indexOf(device.device_id);
      if (ipMapPosition !== -1) {
        const foundDevice = foundDevices[ipMapPosition];
        foundDevice.device = device.device;
        foundDevice.monitoring = device.monitoring;
        foundDevice.serialNumber = device.serialNumber;
        foundDevice.userDeviceId = device.id;
      }
    });

    return dispatch({ type: DeviceActions.SET_FOUND_DEVICES, payload: { foundDevices, publicIp } });
  };
}

export function getLogs(user: User, deviceId: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    const [error, threatResult] = await to(axios.get(`${config.DATABASE_URL}/threat_level.json?auth=${user.userToken}`));
    if (error) {
      return dispatch({ type: AppActions.ERROR, payload: error.message });
    }
    const [deviceError, devicesResult] = await to<FirebaseResult>(axios.get(`${config.DATABASE_URL}/user_devices.json?auth=${user.userToken}`));
    if (deviceError) {
      return dispatch({ type: AppActions.ERROR, payload: deviceError.message });
    }
    const usersDevices = devicesResult?.data;
    const userDevicesIds: string[] = [];
    for (const key in usersDevices) {
      if (Object.prototype.hasOwnProperty.call(usersDevices, key)) {
        const device = usersDevices[key];
        if (device.uid === user.uid && device.monitoring) {
          userDevicesIds.push(device.device_id);
        }
      }
    }
    const threats = threatResult?.data;
    const logs: Log[] = [];
    for (const threatKey in threats) {
      if (Object.prototype.hasOwnProperty.call(threats, threatKey)) {
        const threat = threats[threatKey];
        if (userDevicesIds.indexOf(threat.device) !== -1) {
          if (deviceId) {
            if (threat.device === deviceId) {
              logs.push(threat);
            }
          } else {
            logs.push(threat);
          }
        }
      }
    }
    return dispatch({ type: DeviceActions.SET_LOGS, payload: { logs, deviceId } });
  };
}

export function addDevice(user: User, foundDevices: DeviceIpMap[], selectedDevicesIds: string[]) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    dispatch({ type: DeviceActions.PROCESSING_ADD, payload: true });

    const selectedDevices: DeviceIpMap[] = filter(foundDevices, (device) =>
      selectedDevicesIds.indexOf(device.id) !== -1);
    const devicesToRemoveMonitoring: DeviceIpMap[] = filter(foundDevices, (device) => {
      if ((selectedDevicesIds.indexOf(device.id) === -1 && device.monitoring)) {
        return true;
      }
      return false;
    });
    console.log('174: selectedDevices >>>', selectedDevices);
    console.log('175: devicesToRemoveMonitoring >>>', devicesToRemoveMonitoring);
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < selectedDevices.length; index++) {
      const device = selectedDevices[index];
      const [error, result] = await to(saveUserDevice(user, device, 'add'));
      if (error) {
        console.log('181: error >>>', error);
        return dispatch({ type: AppActions.ERROR, payload: error.message });
      }
      console.log('184: result >>>', result);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let rIndex = 0; rIndex < devicesToRemoveMonitoring.length; rIndex++) {
      const device = devicesToRemoveMonitoring[rIndex];
      const [error, result] = await to(saveUserDevice(user, device, 'remove'));
      if (error) {
        console.log('191: error >>>', error);
        return dispatch({ type: AppActions.ERROR, payload: error.message });
      }
      console.log('194: result >>>', result);
    }
    return batch(() => {
      dispatch({ type: DeviceActions.PROCESSING_ADD, payload: false });
      dispatch(findDevices(user, 'update'));
      dispatch(openConfirmationDialog());
    });
  };
}

export function removeDevice(user: User, device: UserDevices) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    await axios.patch(`${config.DATABASE_URL}/user_devices/${device.id}.json?auth=${user.userToken}`, {
      monitoring: false,
    });
    dispatch(getDevices(user));
  };
}

export function saveDeviceData(user: User, device: DeviceIpMap) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function) => {
    batch(() => {
      dispatch({ type: DeviceActions.SET_PROCESSING_SAVE, payload: true });
      dispatch(clearDeviceDialog());
    });
    const [error] = await to(saveUserDevice(user, device));
    if (error) {
      console.log('223: error >>>', error);
      return batch(() => {
        dispatch({ type: AppActions.ERROR, payload: error.message });
        dispatch({ type: DeviceActions.SET_PROCESSING_SAVE, payload: false });
      });
    }
    return dispatch({ type: DeviceActions.UPDATE_DEVICE, payload: device });
  };
}

export function filterDevices(keyword: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function, getState: () => RootState) => {
    const devices = getState().device.devices;
    if (!keyword) {
      return dispatch({ type: DeviceActions.SET_DEVICES, payload: devices });
    }
    const regExp = new RegExp(keyword, 'i');
    const filteredDevices = filter(devices, (item) => {
      if (item.device_id.match(regExp) ||
        item.device.match(regExp) ||
        item.private.match(regExp) ||
        (item.serialNumber && item.serialNumber.match(regExp))) {
        return true;
      }
      return false;
    });
    return dispatch({ type: DeviceActions.SET_FILTERED_DEVICES, payload: filteredDevices });
  };
}

export function filterFoundDevices(keyword: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function, getState: () => RootState) => {
    const deviceStore = getState().device;
    const devices = deviceStore.foundDevices;
    if (!keyword) {
      return dispatch({
        type: DeviceActions.SET_FOUND_DEVICES,
        payload: { foundDevices: devices, publicIp: deviceStore.publicIp },
      });
    }
    const regExp = new RegExp(keyword, 'i');
    const filteredDevices = filter(devices, (item) => {
      if (
        item.device_id.match(regExp)
        || item.device.match(regExp)
        || (item.serialNumber && item.serialNumber.match(regExp))
      ) {
        return true;
      }
      return false;
    });
    return dispatch({ type: DeviceActions.SET_FILTERED_FOUND_DEVICES, payload: filteredDevices });
  };
}

export function filterLogs(keyword: string) {
  // tslint:disable-next-line: ban-types
  return async (dispatch: Function, getState: () => RootState) => {
    const deviceStore = getState().device;
    const logs = deviceStore.logs;
    if (!keyword) {
      return dispatch({
        type: DeviceActions.SET_LOGS,
        payload: { logs, deviceId: deviceStore.lastDeviceId },
      });
    }
    const regExp = new RegExp(keyword, 'i');
    const filteredLogs = filter(logs, (item) => {
      if (
        item.device.match(regExp)
        || item.threat_description.match(regExp)
      ) {
        return true;
      }
      return false;
    });
    return dispatch({ type: DeviceActions.SET_FILTERED_LOGS, payload: filteredLogs });
  };
}

export function setSelectedDevicesIds(newSelectedDevicesIds: string[]) {
  return { type: DeviceActions.SET_SELECTED_DEVICES, payload: newSelectedDevicesIds };
}

export function openThreatDialog(threat: Threat) {
  return { type: DeviceActions.OPEN_THREAT_DIALOG, payload: threat };
}

export function clearThreatDialog() {
  return { type: DeviceActions.CLEAR_THREAT_DIALOG };
}

export function openDeviceDialog(data: DeviceDialog) {
  return { type: DeviceActions.OPEN_DEVICE_DIALOG, payload: data };
}

export function openConfirmationDialog() {
  return { type: DeviceActions.OPEN_CONFIRMATION_DIALOG };
}

export function setLastDeviceId(deviceId: string): DeviceAction {
  return { type: DeviceActions.SET_LAST_DEVICE_ID, payload: deviceId };
}

export function clearDeviceDialog() {
  return { type: DeviceActions.CLEAR_DEVICE_DIALOG };
}

export function closeSearchingModal() {
  return { type: DeviceActions.CLOSE_SEARCHING_MODAL };
}

export function closeConfirmationDialog() {
  return { type: DeviceActions.CLOSE_CONFIRMATION_DIALOG };
}
export function closeUpdateConfirmationDialog() {
  return { type: DeviceActions.CLOSE_UPDATE_CONFIRMATION_DIALOG };
}

export function setDeviceValue(
  action: DeviceActions.SET_SERIAL_NUMBER | DeviceActions.SET_DEVICE_NAME, value: string
) {
  return { type: action, payload: value };
}

export enum AppActions {
  ERROR = 'ERROR',
}

export function setError(message: null |string) {
  return {
    type: AppActions.ERROR,
    payload: message,
  };
}

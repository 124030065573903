import filter from 'lodash/filter';
import map from 'lodash/map';
import { DeviceActions } from '../actions/devices.actions';
import createReducer from './createReducer';

const initialState: DeviceReducer = {
  devices: [],
  filteredDevices: [],
  foundDevices: [],
  filteredFoundDevices: [],
  selectedFoundDevicesIds: [],
  logs: [],
  filteredLogs: [],
  lastDeviceId: '',
  publicIp: '0.0.0.0',
  lastAction: null,
  threatDialog: {
    open: false,
    threat: null,
  },
  deviceDialog: {
    open: false,
    device: null,
    processingSave: false,
  },
  processingAdd: false,
  confirmationDialog: false,
  updateConfirmationDialog: false,
  searchingDevices: false,
};

export const device = createReducer<DeviceReducer>(initialState, {
  [DeviceActions.SET_DEVICES](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      devices: action.payload,
      lastAction: DeviceActions.SET_DEVICES,
    };
  },
  [DeviceActions.SET_FILTERED_DEVICES](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      filteredDevices: action.payload,
      lastAction: DeviceActions.SET_FILTERED_DEVICES,
    };
  },
  [DeviceActions.SET_FOUND_DEVICES](state: DeviceReducer, action: any) {
    const selectedFoundDevices = action.payload.foundDevices.length ?
      filter(action.payload.foundDevices, (item) => item.monitoring) : [];
    const selectedFoundDevicesIds = map(selectedFoundDevices, (item) => item.id);
    return {
      ...state,
      selectedFoundDevicesIds,
      foundDevices: action.payload.foundDevices,
      publicIp: action.payload.publicIp,
      lastAction: DeviceActions.SET_FOUND_DEVICES,
      searchingDevices: false,
    };
  },
  [DeviceActions.SET_FILTERED_FOUND_DEVICES](state: DeviceReducer, action: any) {
    const selectedFoundDevices = action.payload.length ?
      filter(action.payload, (item) => item.monitoring) : [];
    const selectedFoundDevicesIds = map(selectedFoundDevices, (item) => item.id);
    return {
      ...state,
      selectedFoundDevicesIds,
      filteredFoundDevices: action.payload,
      lastAction: DeviceActions.SET_FILTERED_FOUND_DEVICES,
    };
  },
  [DeviceActions.SET_SELECTED_DEVICES](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      selectedFoundDevicesIds: action.payload,
    };
  },
  [DeviceActions.SEARCHING](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      searchingDevices: action.payload,
    };
  },
  [DeviceActions.PROCESSING_ADD](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      processingAdd: action.payload,
    };
  },
  [DeviceActions.SET_LOGS](state: DeviceReducer, action: any) {
    return {
      ...state,
      logs: action.payload.logs,
      lastAction: DeviceActions.SET_LOGS,
      lastDeviceId: action.payload.deviceId,
    };
  },
  [DeviceActions.SET_FILTERED_LOGS](state: DeviceReducer, action: any) {
    return {
      ...state,
      filteredLogs: action.payload,
      lastAction: DeviceActions.SET_FILTERED_LOGS,
    };
  },
  [DeviceActions.OPEN_THREAT_DIALOG](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      threatDialog: {
        open: true,
        threat: action.payload,
      },
    };
  },
  [DeviceActions.CLEAR_THREAT_DIALOG](state: DeviceReducer) {
    return {
      ...state,
      threatDialog: {
        open: false,
        threat: null,
      },
    };
  },
  [DeviceActions.OPEN_DEVICE_DIALOG](state: DeviceReducer, action: any) {
    return {
      ...state,
      deviceDialog: {
        device: action.payload,
        open: true,
      },
    };
  },
  [DeviceActions.UPDATE_DEVICE](state: DeviceReducer, action: { payload: DeviceIpMap }) {
    const payload = action.payload;
    const currentDeviceIpMap = filter(state.foundDevices, (item) =>
      item.device_id === payload.device_id)[0];

    currentDeviceIpMap.device = payload.device;
    currentDeviceIpMap.monitoring = payload.monitoring;
    currentDeviceIpMap.serialNumber = payload.serialNumber;

    return {
      ...state,
      foundDevices: state.foundDevices,
      updateConfirmationDialog: true,
    };
  },
  [DeviceActions.OPEN_CONFIRMATION_DIALOG](state: DeviceReducer) {
    return {
      ...state,
      confirmationDialog: true,
    };
  },
  [DeviceActions.CLOSE_CONFIRMATION_DIALOG](state: DeviceReducer) {
    return {
      ...state,
      confirmationDialog: false,
    };
  },
  [DeviceActions.CLOSE_UPDATE_CONFIRMATION_DIALOG](state: DeviceReducer) {
    return {
      ...state,
      updateConfirmationDialog: false,
    };
  },
  [DeviceActions.CLEAR_DEVICE_DIALOG](state: DeviceReducer) {
    return {
      ...state,
      deviceDialog: {
        device: null,
        open: false,
        processingSave: false,
      },
    };
  },
  [DeviceActions.CLOSE_SEARCHING_MODAL](state: DeviceReducer) {
    return {
      ...state,
      searchingDevices: false,
    };
  },
  [DeviceActions.SET_LAST_DEVICE_ID](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      lastDeviceId: action.payload,
    };
  },
  [DeviceActions.SET_DEVICE_NAME](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      deviceDialog: {
        open: state.deviceDialog.open,
        device: {
          ...state.deviceDialog.device,
          device: action.payload,
        },
      },
    };
  },
  [DeviceActions.SET_SERIAL_NUMBER](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      deviceDialog: {
        open: state.deviceDialog.open,
        device: {
          ...state.deviceDialog.device,
          serialNumber: action.payload,
        },
      },
    };
  },
  [DeviceActions.SET_PROCESSING_SAVE](state: DeviceReducer, action: DeviceAction) {
    return {
      ...state,
      deviceDialog: {
        ...state.deviceDialog,
        processingSave: action.payload,
      },
    };
  },
  [DeviceActions.CLEAR]() {
    return initialState;
  },
});

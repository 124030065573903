import firebase from 'firebase/app';
import 'firebase/auth';

// Config
import config from '../config';

class Firebase {

  private app: firebase.app.App | null = null;
  private user: firebase.User | null = null;

  init() {
    this.app = firebase.apps.length ? firebase.apps[0] : firebase.initializeApp({
      apiKey: config.API_KEY,
      authDomain: config.AUTH_DOMAIN,
      databaseURL: config.DATABASE_URL,
      projectId: config.PROJECT_ID,
      storageBucket: config.STORAGE_BUCKET,
      messagingSenderId: config.MESSAGING_SENDER_ID,
      appId: config.APP_ID,
      measurementId: config.MEASUREMENT_ID,
    });
    // this.authStateChanged();
    return this.app;
  }

  authStateChanged() {
    this.app?.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // var uid = user.uid;
        console.log('35: user >>>', firebaseUser);
        this.user = firebaseUser;
      } else {
        // User is signed out
        this.user = null;
      }
    });
  }

  getUser() {
    return this.user;
  }

}

export default Firebase;

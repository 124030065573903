import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { Critical, High, Low, None } from '../devices/icons';
import ThreatIcon from '../../components/ThreatIcon';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const drawIcon = (threatLevel: string) => {
  switch (Number(threatLevel)) {
    case 0:
      return <None height={30} />;
    case 1:
      return <Low height={30} />;
    case 2:
      return <High height={30} />;
    case 3:
      return <Critical height={30} />;
    default:
      return null;
  }
};

interface Porps {
  className?: string;
  logs: Log[];
}

interface DescriptionProps {
  data: { open: boolean, log: Log | null };
  onClose: () => void;
}

function DescriptionDialog({ onClose, data }: DescriptionProps) {
  const date = data.log?.date ? new Date(data.log?.date).toLocaleString() : '';
  return (
    <div>
      <Dialog
        open={data.open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='center' m={0.5}>
            <Box>
              <ThreatIcon
                threat={data.log}
                height={40}
                color='primary'
                style={{ fontSize: 40 }}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='center'>
            <Box>
              <Typography color='textPrimary' variant='h4'>
                Threat Description
            </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {data.log?.threat_description}
          </DialogContentText>
          <DialogContentText>
            {date}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary' variant='contained' autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  date: string;
  device: string;
  threat_description: string;
  threat_level: string;
}

const headCells: HeadCell[] = [
  { id: 'device', numeric: false, disablePadding: true, label: 'Device ID' },
  { id: 'threat_level', numeric: false, disablePadding: false, label: 'Threat Level' },
  { id: 'threat_description', numeric: false, disablePadding: false, label: 'Threat Description' },
  { id: 'date', numeric: false, disablePadding: false, label: 'Date / Time' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// tslint:disable-next-line: variable-name
const Results = ({
  className,
  logs,
  ...rest
}: Porps) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [log, setLog] = useState<Log | null>(null);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('date');

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleCloseThreat = () => {
    setOpenDialog(false);
    setLog(null);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <DescriptionDialog data={{ open: openDialog, log }} onClose={handleCloseThreat} />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(logs, getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit).map((item: Log, index: number) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell padding='checkbox'>
                      <IconButton onClick={() => {
                        setLog(item);
                        setOpenDialog(true);
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems='center'
                        display='flex'
                      >
                        {item.device}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {drawIcon(item.threat_level)}
                    </TableCell>
                    <TableCell>
                      <Box component='div' display='inline'>
                        {item.threat_description}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.date}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={logs.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default Results;

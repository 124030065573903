import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Logo from '../../components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  logoLink: {
    height: '100%',
    padding: '5px 0',
  }
}));

interface Props {
  className?: string;
}

// tslint:disable-next-line: variable-name
const TopBar = ({
  className,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar style={{ height: 64 }}>
        <RouterLink to='/' className={classes.logoLink}>
          <Logo style={{ height: '100%' }} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

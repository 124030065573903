import { UserActions } from '../actions/user.actions';
import createReducer from './createReducer';

const initialState: UserReducer = {
  data: {
    id: '',
    uid: '',
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    additionalUserInfo: {
      isNewUser: false,
      providerId: 'string',
    },
    displayName: '',
    emailVerified: false,
    refreshToken: '',
    userToken: '',
  },
  loginSuccess: false,
  processingUpdate: false,
  updatePasswordError: '',
  updatePasswordSuccess: '',
  updateUserSuccess: '',
};

export const user = createReducer<UserReducer>(initialState, {
  [UserActions.SET_USER](state: UserReducer, action: UserAction) {
    return {
      data: action.payload,
      loginSuccess: true,
    };
  },
  [UserActions.SET_UPDATED_USER](state: UserReducer, action: UserAction) {
    return {
      ...state,
      data: {
        ...state.data,
        companyName: action.payload.companyName,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        displayName: action.payload.firstName,
      },
      updateUserSuccess: 'Profile updated!',
    };
  },
  [UserActions.SET_UPDATED_PASSWORD_ERROR](state: UserReducer, action: UserAction) {
    return {
      ...state,
      updatePasswordError: action.payload,
    };
  },
  [UserActions.SET_UPDATED_PASSWORD_SUCCESS](state: UserReducer, action: UserAction) {
    return {
      ...state,
      updatePasswordSuccess: action.payload,
    };
  },
  [UserActions.CLOSE_USER_ALERTS](state: UserReducer, action: UserAction) {
    return {
      ...state,
      updatePasswordError: '',
      updatePasswordSuccess: '',
      updateUserSuccess: '',
    };
  },
  [UserActions.LOG_OUT]() {
    return initialState;
  },
});

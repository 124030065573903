import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'nuka-carousel';

import Page from '../../components/Page';

import * as  AuthActions from '../../actions/user.actions';
import { useActions } from '../../actions';

const namesRegExp = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/, 'u');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120%',
    backgroundPositionX: -45,
    backgroundPositionY: 50,
    height: '70%',
  },
  gridContainer: {
    height: '100%',
  },
  gridItemContainer: {
    height: 'auto',
    overflow: 'hidden',
    maxHeight: 800,
  },
  titleCarrousel: {
    color: '#fff',
  },
}));

interface ItemProps {
  item: {
    name: string;
    description: string;
    img: string;
  };
}

interface TabPanelProps {
  children?: any;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth='xs'>
          {children}
        </Container>
      )}
    </div>
  );
}

function Item(props: ItemProps) {
  const classes = useStyles();
  const { item } = props;

  return (
    <>
      <div style={{
        backgroundImage: `url(${item.img})`
      }} className={classes.imageContainer}
      ></div>
      <div>
        <Box display='flex' justifyContent='center' m={1} p={1} >
          <Box p={1}>
            <Typography variant='h2' className={classes.titleCarrousel}>
              {item.name}
            </Typography>
            <Typography variant='h5' className={classes.titleCarrousel}>
              {item.description}
            </Typography>
          </Box>
        </Box>
      </div>
    </>
  );
}
// tslint:disable-next-line: variable-name
const RegisterView = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState('a@a.com');
  const items = [{
    name: 'Exposes',
    description: 'Exposes hidden threats on your Wi-Fi network',
    img: '/assets/SOC-Login-Images-1.png',
  }, {
    name: 'Reports',
    description: 'Reports attacks and vulnerabilities color-coded by threat level',
    img: '/assets/SOC-Login-Images-2.png',
  }, {
    name: 'Mitigates',
    description: 'Mitigates threats for every device it finds on your Wi-Fi',
    img: '/assets/SOC-Login-Images-3.png',
  }];

  const authActions = useActions(AuthActions);

  return (
    <Page
      className={classes.root}
      title='Sign up'
    >
      <Container maxWidth='lg' style={{ height: '100vh', maxHeight: 800 }}>
        <Grid container className={classes.gridContainer}>
          <Hidden only={['xs']}>
            <Grid item md={5} sm={5} style={{ backgroundColor: '#2295D3', }} className={classes.gridItemContainer}>
              <Carousel
                height={'100vh'}
                autoplay={true}
                enableKeyboardControls={true}
                autoGenerateStyleTag={true}
                autoplayReverse={true}
              >
                {items.map((item, index) => (
                  <Item key={index} item={item} />
                ))}
              </Carousel>
            </Grid>
          </Hidden>
          <Grid item md={7} sm={7} xs={12} className={classes.gridItemContainer}>
            <Box
              display='flex'
              flexDirection='column'
              height='100%'
              alignContent='center'
              justifyContent='center'
            >
              <TabPanel value={tab} index={0}>
                <Formik
                  initialValues={{
                    email: '',
                    firstName: '',
                    lastName: '',
                    companyName: '',
                    password: '',
                    newPassword: '',
                    policy: false,
                    eula: false,
                    error: { code: '', message: '' },
                  }}
                  validationSchema={
                    Yup.object().shape({
                      firstName: Yup.string()
                        .matches(namesRegExp, 'Do not special charters (@,!,#, etc).')
                        .max(255).required('First name is required'),
                      lastName: Yup.string()
                        .matches(namesRegExp, 'Do not special charters (@,!,#, etc).')
                        .max(255).required('Last name is required'),
                      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                      companyName: Yup.string().max(255).required('Company name is required'),
                      password: Yup.string().max(255).required('Password is required'),
                      newPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match'),
                      policy: Yup.boolean().oneOf([true], 'This Policy field must be checked'),
                      eula: Yup.boolean().oneOf([true], 'This EULA field must be checked'),
                      error: Yup.object().shape({
                        code: Yup.string(),
                        message: Yup.string(),
                      }),
                    })
                  }
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    if (values.newPassword.toLocaleLowerCase() !== values.password.toLocaleLowerCase()) {
                      setSubmitting(false);
                      return setErrors({ newPassword: 'Password does not match' });
                    }
                    authActions.signUp(values).then(() => {
                      setEmail(values.email);
                      setTab(1);
                    }).catch((error: Error) => {
                      setSubmitting(false);
                      let code = '';
                      const message = error.message.replace(/-.+/, (result) => {
                        code = result;
                        return '';
                      });
                      setErrors({ error: { message, code } });
                    });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={2}>
                        <Typography
                          color='textPrimary'
                          variant='h2'
                        >
                          Sign up
                        </Typography>
                        <Typography
                          color='textSecondary'
                          gutterBottom
                          variant='body2'
                        >
                          Sign up with your basic personal information and start monitoring your network
                        </Typography>
                      </Box>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        size='small'
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label='First name'
                        margin='normal'
                        name='firstName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        size='small'
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label='Last name'
                        margin='normal'
                        name='lastName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.companyName && errors.companyName)}
                        size='small'
                        fullWidth
                        helperText={touched.companyName && errors.companyName}
                        label='Company Name'
                        margin='normal'
                        name='companyName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyName}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        size='small'
                        fullWidth
                        helperText={touched.email && errors.email}
                        label='Email Address'
                        margin='normal'
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='email'
                        value={values.email}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        size='small'
                        fullWidth
                        helperText={touched.password && errors.password}
                        label='Password'
                        margin='normal'
                        name='password'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='password'
                        value={values.password}
                        variant='outlined'
                      />
                      <TextField
                        error={Boolean(touched.newPassword && errors.newPassword)}
                        size='small'
                        fullWidth
                        helperText={touched.newPassword && errors.newPassword}
                        label='Confirm New Password'
                        margin='normal'
                        name='newPassword'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='password'
                        value={values.newPassword}
                        variant='outlined'
                      />
                      <Box
                        alignItems='center'
                        display='flex'
                        ml={-1}
                      >
                        <Checkbox
                          checked={values.policy}
                          name='policy'
                          onChange={handleChange}
                        />
                        <Typography
                          color='textSecondary'
                          variant='body1'
                        >
                          I agree to the
                          {' '}
                          <Link
                            color='primary'
                            href='https://guarddog.ai/contact-3/contact/'
                            target='_blank'
                            underline='always'
                            variant='h6'
                          >
                            terms of service
                          </Link>
                          {' '}
                          and
                          {' '}
                          <Link
                            color='primary'
                            href='https://guarddog.ai/contact-3/contact/'
                            target='_blank'
                            underline='always'
                            variant='h6'
                          >
                            privacy policy
                          </Link>
                        </Typography>
                      </Box>
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>
                          {errors.policy}
                        </FormHelperText>
                      )}
                      <Box
                        alignItems='center'
                        display='flex'
                        ml={-1}
                      >
                        <Checkbox
                          checked={values.eula}
                          name='eula'
                          onChange={handleChange}
                        />
                        <Typography
                          color='textSecondary'
                          variant='body1'
                        >
                          I accept the
                          {' '}
                          <Link
                            color='primary'
                            href='https://guarddog.ai/contact-3/contact/'
                            target='_blank'
                            underline='always'
                            variant='h6'
                          >
                            End-user license agreement
                          </Link>
                        </Typography>
                      </Box>
                      {Boolean(touched.eula && errors.eula) && (
                        <FormHelperText error>
                          {errors.eula}
                        </FormHelperText>
                      )}
                      {Boolean(touched.error?.message && errors.error?.message) && (
                        <FormHelperText error>
                          {errors.error?.message}
                          {' '}
                          {errors.error?.code && <Typography className={classes.root}>
                            <Link
                              component={RouterLink}
                              color='error'
                              to='/login?action=recovpass'
                              variant='body2'
                            >
                              Maybe you want to recover your password?
                            </Link>
                          </Typography>}
                        </FormHelperText>
                      )}
                      <Box my={2}>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          fullWidth
                          size='large'
                          type='submit'
                          variant='contained'
                        >
                          Sign up now
                        </Button>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        {isSubmitting && <Box my={1}><CircularProgress /></Box>}
                      </Box>
                      <Typography
                        color='textSecondary'
                        variant='body1'
                      >
                        Already have an account?
                        {' '}
                        <Link
                          component={RouterLink}
                          to='/login'
                          variant='h6'
                        >
                          Log in
                        </Link>
                      </Typography>
                    </form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Box mb={3}>
                  <Typography
                    color='textPrimary'
                    variant='h2'
                  >
                    Email verification
                  </Typography>
                  <Typography
                    color='textSecondary'
                    gutterBottom
                    variant='body2'
                  >
                    We have sent a mail to {email} with the instruction for your email validation.
                  </Typography>
                  <Box my={2}>
                    {/* <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='body2'
                    >
                      If you have not received a verification email, you can resend the verification mail.
                    </Typography> */}
                    <Typography
                      color='textSecondary'
                      gutterBottom
                      variant='body2'
                    >
                      Please contact with support if you do not receive any mail, to: anant@guarddog.ai
                    </Typography>
                    {/* <Formik
                      initialValues={{
                        email,
                        error: '',
                        message: '',
                      }}
                      validationSchema={
                        Yup.object().shape({
                          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                          error: Yup.string(),
                          message: Yup.string(),
                        })
                      }
                      onSubmit={(values, { setSubmitting, setErrors }) => {
                        authActions.resendEmailVerification(values.email).then(() => {
                          setSubmitting(false);
                          setErrors({ message: 'Email sent!' });
                        }).catch((error: Error) => {
                          setSubmitting(false);
                          setErrors({ error: error.message });
                        });
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            disabled
                            size='small'
                            fullWidth
                            helperText={touched.email && errors.email}
                            label='Email Address'
                            margin='normal'
                            name='email'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='email'
                            value={values.email}
                            variant='outlined'
                          />
                          {Boolean(touched.error && errors.error) && (
                            <FormHelperText error>
                              {errors.error}
                            </FormHelperText>
                          )}
                          <Box my={2}>
                            <Button
                              color='primary'
                              disabled={isSubmitting}
                              fullWidth
                              size='large'
                              type='submit'
                              variant='contained'
                            >
                              Resend
                            </Button>
                          </Box>
                          {Boolean(touched.message && errors.message) && (
                            <Alert severity='success'>
                              {errors.message}
                            </Alert>
                          )}
                        </form>
                      )}
                    </Formik> */}
                  </Box>
                  <Box mt={5}>
                    <Typography
                      color='textSecondary'
                      variant='body1'
                    >
                      Back to
                        {' '}
                      <Link
                        component={RouterLink}
                        to='/login'
                        variant='h6'
                      >
                        Log in
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default RegisterView;

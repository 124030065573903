import React from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  root: {}
}));

const namesRegExp = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/, 'u');

interface Props {
  className?: string;
  user: User;
  onSave: (data: UserInfo) => void;
  processing: boolean;
}

// tslint:disable-next-line: variable-name
const ProfileDetails = ({
  className, user, onSave, processing, ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        companyName: user.companyName,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .matches(namesRegExp, 'Do not special charters (@,!,#, etc).')
          .max(255).required('First name is required'),
        lastName: Yup.string()
          .matches(namesRegExp, 'Do not special charters (@,!,#, etc).')
          .max(255).required('Last name is required'),
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        companyName: Yup.string().max(255).required('Company name is required'),
      })}
      onSubmit={(values) => {
        // TODO
        console.log('56: values >>>', values);
        const data = {
          id: user.id,
          uid: user.uid,
          ...values,
        };
        onSave(data);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader='The information can be edited'
              title='Profile'
            />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label='First name'
                    name='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    variant='outlined'
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label='Last name'
                    name='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    variant='outlined'
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label='Email Address'
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    variant='outlined'
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                >
                  <TextField
                    error={Boolean(touched.companyName && errors.companyName)}
                    fullWidth
                    helperText={touched.companyName && errors.companyName}
                    label='Company Name'
                    name='companyName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Box
              display='flex'
              justifyContent='flex-end'
              p={2}
            >
              {processing && <CircularProgress />}
              {!processing && <Button
                color='primary'
                variant='contained'
                type='submit'
              >
                Save Changes
              </Button>}
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ProfileDetails;

import React, { useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RouterIcon from '@material-ui/icons/Router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import InfoIcon from '@material-ui/icons/Info';

import * as DeviceActions from '../../actions/devices.actions';
import { useActions } from '../../actions';
// Modules
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface DeviceNameProps {
  data: DeviceDialog;
  onClose: () => void;
  onChange: (action: DeviceActions.DeviceActions, value: string) => void;
  saveFunc: () => void;
}

interface SearchingModalProps {
  open: boolean;
  onClose: () => void;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  device: string;
  device_id: string;
  private: string;
  public: string;
  serialNumber: string;
}

const headCells: HeadCell[] = [
  { id: 'device', numeric: false, disablePadding: true, label: 'Device Name' },
  { id: 'serialNumber', numeric: false, disablePadding: false, label: 'Serial Number' },
  { id: 'device_id', numeric: false, disablePadding: false, label: 'Device ID' },
  { id: 'private', numeric: false, disablePadding: false, label: 'Private IP' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
        </TableCell>
        <TableCell>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function DeviceDialog({ data, onClose, saveFunc, onChange }: DeviceNameProps) {
  return (
    <div>
      <Dialog
        open={data.open}
        fullWidth={true}
        maxWidth={'xs'}
        onClose={onClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <Typography color='textSecondary' variant='h4'>
            Edit Device Data
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To identify the Device within a Network
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='deviceName'
            label='Device Name'
            fullWidth
            value={data.device?.device}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              onChange(DeviceActions.DeviceActions.SET_DEVICE_NAME, event.target.value);
            }}
          />
          <TextField
            autoFocus
            margin='dense'
            id='serialNumber'
            label='Serial Number'
            fullWidth
            value={data.device?.serialNumber}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              onChange(DeviceActions.DeviceActions.SET_SERIAL_NUMBER, event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          {!data.processingSave &&
            <>
              <Button onClick={onClose} color='primary' variant='contained'>
                Cancel
              </Button>
              <Button onClick={() => saveFunc()} color='primary' variant='contained'>
                Save
              </Button>
            </>
          }
          {data.processingSave && <CircularProgress />}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SearchingModal(props: SearchingModalProps) {
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' justifyContent='center' m={0.5}>
          <Box>
            <WifiTetheringIcon color='primary' style={{ fontSize: 50 }} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box>
            <Typography color='textPrimary' variant='h4'>
              Looking For Devices
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Our platform is detecting if some guardDog device is connected in your same IP Network.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

function ConfirmationModal(props: SearchingModalProps) {
  return (
    <Dialog
      open={props.open}
      maxWidth={'xs'}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' justifyContent='center' m={0.5}>
          <Box>
            <RouterIcon color='primary' style={{ fontSize: 50 }} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box>
            <Typography color='textPrimary' variant='h4'>
              Devices Registered
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You can see the updates on the Devices section
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color='primary' variant='contained' autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function UpdateConfirmationModal(props: SearchingModalProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={'xs'}
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' justifyContent='center' m={0.5}>
          <Box>
            <InfoIcon color='primary' style={{ fontSize: 50 }} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box>
            <Typography color='textPrimary' variant='h4'>
              Device Updated
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your Device has been updated
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color='primary' variant='contained' autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  className?: string;
  user: User;
  devices: DeviceIpMap[];
  handleSelectAll: (event: { target: { checked: any; }; }) => void;
  handleSelectOne: (_: any, id: string) => void;
  selectedDevicesIds: string[];
}

// tslint:disable-next-line: variable-name
const Results = ({
  className,
  devices,
  handleSelectAll,
  handleSelectOne,
  selectedDevicesIds,
  user,
  ...rest
}: Props) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('device');

  const Device = useSelector((state: RootState) => state.device);
  const deviceActions = useActions(DeviceActions);

  const handleLimitChange = (event: any) => {
    // tslint:disable-next-line: radix
    setLimit(parseInt(event.target.value));
    setPage(0);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSaveDevice = () => {
    deviceActions.saveDeviceData(user, Device.deviceDialog.device);
  };

  const handleCloseDeviceName = () => {
    deviceActions.clearDeviceDialog();
  };

  const handleDeviceChange = (action: DeviceActions.DeviceActions, value: string) => {
    deviceActions.setDeviceValue(action, value);
  };

  const handleOpenDevice = (device: DeviceIpMap) => {
    deviceActions.openDeviceDialog(device);
  };

  const handleCloseSearchingModal = () => {
    deviceActions.closeSearchingModal();
  };

  const handleCloseConfirmationModal = () => {
    deviceActions.closeConfirmationDialog();
  };

  const handleCloseUpdateConfirmationModal = () => {
    deviceActions.closeUpdateConfirmationDialog();
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <DeviceDialog
        data={Device.deviceDialog}
        onClose={handleCloseDeviceName}
        onChange={handleDeviceChange}
        saveFunc={handleSaveDevice}
      />
      <SearchingModal
        open={Device.searchingDevices}
        onClose={handleCloseSearchingModal}
      />
      <ConfirmationModal
        open={Device.confirmationDialog}
        onClose={handleCloseConfirmationModal}
      />
      <UpdateConfirmationModal
        open={Device.updateConfirmationDialog}
        onClose={handleCloseUpdateConfirmationModal}
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(devices, getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((device: DeviceIpMap, index: number) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={
                          selectedDevicesIds.indexOf(device.id) !== -1}
                        onChange={(event) => handleSelectOne(event, device.id)}
                        value='true'
                      />
                    </TableCell>
                    <TableCell padding='checkbox' style={{ width: 30 }}>
                      <IconButton onClick={() => handleOpenDevice(device)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell style={{ maxWidth: 50 }}>
                      <Box
                        alignItems='center'
                        display='flex'
                      >
                        {device.device}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {device.serialNumber}
                    </TableCell>
                    <TableCell>
                      <Box
                        alignItems='center'
                        display='flex'
                      >
                        {device.device_id}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {device.private}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={devices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card >
  );
};

export default Results;
